import logo from "./assets/logo.svg";
import bgImg from "./assets/singleBg.svg";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import "./App.css";
import {
  Layout,
  Wizard,
  Box,
  Modal,
  ModalBody,
  Notify,
  Text,
  Spacer,
} from "@flexisaf/flexibull2";
import { Theme } from "./config/theme";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "./assets/fonts/fontello/css/saf.css";
import AboutYou from "./school-signup/AboutYou";
import AboutTheSchool from "./school-signup/AboutTheSchool";
import AboutSchoolSize from "./school-signup/AboutSchoolSize";
import SelectModules from "./school-signup/SelectModules";
import BgBlur from "./assets/bg-blur.png";
import Timer from "./assets/timer.svg";
import { pageview } from "react-ga";
import { useGoogleAnalytics } from "./utils/GoogleAnalytics";
import httpClient from "./utils/http-client";
import useCreateSchool from "./hooks/useCreateSchool";
import useBreakpointValue from "./utils/useBreakpointValue/useBreakpointValue";
import MailSent from "./mailsent";
import AppConfig from "./utils/config";

const App = () => {
  ReactGA.initialize(AppConfig.MEASUREMENT_ID);

  useEffect(() => {
    TagManager.initialize({ gtmId: AppConfig.GTM_ID });
  }, []);

  const defaultResponse = localStorage.getItem("umsResponse");
  const parsed = JSON.parse(defaultResponse);
  // const [aboutSchoolSize, setAboutSchoolSize] = useState({});
  const [umsResponse, setUmsResponse] = useState(parsed);
  const [aboutYou, setAboutYou] = useState({});
  const [view, setView] = useState(0);
  const [aboutSchool, setAboutSchool] = useState({});
  const [isFinished, setIsFinished] = useState(!!parsed || false);
  const [defaultCountry, setDefaultCountry] = useState({});

  const { loading, createSchool } = useCreateSchool({
    callback: (data) => {
      setUmsResponse(data);
      setIsFinished(true);
    },
  });

  const onFinish = (data) => {
    setAboutYou(data);
    createSchool({
      ...data,
      ...aboutSchool,
    });
  };

  const width = useBreakpointValue({
    base: "97%",
    md: "70vw",
  });

  const height = useBreakpointValue({
    base: "auto",
    md: "auto",
  });

  const renderWizard = () => {
    return (
      <Modal open={true}>
        <ModalBody width={width} style={{ height }}>
          <Box pad="30px">
            <img src={logo} alt="SAFSIMS logo" height="30" />
          </Box>
          <Box>
            {!isFinished ? (
              <Wizard
                title="School SignUp"
                autoProceed={false}
                view={view}
                disableLabels
                style={{ boxShadow: "none", background: "transparent" }}
                elevate={false}
                buttonAlign="left"
              >
                <div title="About the school">
                  <AboutTheSchool
                    setView={setView}
                    view={view}
                    setAboutSchool={setAboutSchool}
                    setDefaultCountry={setDefaultCountry}
                  />
                </div>
                <div title="About You">
                  <AboutYou
                    setView={setView}
                    view={view}
                    onFinish={onFinish}
                    loading={loading}
                    defaultCountry={defaultCountry}
                  />
                </div>
                {/* <div title="About your school size">
              <AboutSchoolSize
                setView={setView}
                view={view}
                setAboutSchoolSize={setAboutSchoolSize}
              />
            </div> */}
                {/* <div title="Select your modules">
              <SelectModules
                setView={setView}
                view={view}
                schoolData={aboutSchool}
                staffData={aboutYou}
                schoolSize={aboutSchoolSize}
                setIsFinished={setIsFinished}
                setEmail={setEmail}
              />
            </div> */}
              </Wizard>
            ) : (
              <Box aligned="center">
                <img src={Timer} height="80px" />
                <Spacer />
                <Text>Finishing setup…</Text>
                <Spacer />
                <Text>
                  Please be patient as we are finishing your school setup this
                  wouldn’t take too long, once we are done, you will be
                  redirected to your schools portal
                </Text>
              </Box>
            )}
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Layout theme={Theme}>
            <Box relative style={{ overflow: "hidden" }} height="100vh">
              {!isFinished ? (
                renderWizard()
              ) : (
                <MailSent
                  email={aboutYou?.email}
                  url={aboutSchool}
                  umsResponse={umsResponse}
                />
              )}
              <img src={BgBlur} height="100%" alt="backround-img" />
            </Box>
          </Layout>
        </Route>
        {/* <Layout theme={Theme}>
          <Route exact path="/mail-sent" component={MailSent} />
        </Layout> */}
      </Switch>
    </Router>
  );
};

export default App;
