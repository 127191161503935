import React, { useCallback, useEffect, useState } from "react";
import { Notify } from "@flexisaf/flexibull2";
import httpClient from "../utils/http-client";

const useValidateSchool = ({ tenantId }) => {
  const [schoolExists, setSchoolExists] = useState(false);

  const validateSchool = useCallback(async () => {
    try {
      const params = {
        "tenant-id": tenantId,
      };
      const { data } = await httpClient.get(`/school-info/validate`, {
        params,
      });
      setSchoolExists(data);
    } catch (error) {
      Notify("Something went wrong!", { status: "error" });
      return;
    }
  }, [tenantId]);

  useEffect(() => {
    validateSchool();
  }, [validateSchool, tenantId]);

  return {
    validateSchool,
    schoolExists,
    setSchoolExists,
  };
};

export default useValidateSchool;
